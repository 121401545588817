import { createContext, useContext, useEffect, useState } from 'react'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { useRouter } from 'next/router'
import ChildrenProvider from './children.provider'
import { browserAPIRequest } from '@/services/api'
import { Alert } from '@/components/alert'
import { IAdmin } from '@/interfaces/admin'
import { useLoading } from './loading'

interface IAuthContextData {
    login(email: string, password: string, callback?: () => void): void,
    logout(callback?: () => void): void,
    user: IAdmin | undefined,
    token: string | undefined,
}

interface IUserAuth {
    email: string,
    password: string,
}

export const AuthContext = createContext({} as IAuthContextData)
export const token_name = 'token_escotilha'
export const refresh_token_name = 'refresh_token_escotilha'

export const AuthProvider = ({ children }: ChildrenProvider): JSX.Element => {

    const [user, setUser] = useState<IAdmin | undefined>()
    const [token, setToken] = useState<string>('')
    const { setIsLoading } = useLoading()
    const router = useRouter()
    let getNewTokenInterval: NodeJS.Timer

    useEffect((): () => void => {
        async function getNewToken(): Promise<void> {
            const cookies = parseCookies()

            const refreshTokenBody = {
                refresh_token: cookies[refresh_token_name]
            }

            if (!refreshTokenBody.refresh_token) {
                return
            }
            setIsLoading(true)

            try {

                const refreshTokenResponse = await browserAPIRequest
                    .post(`/admins/login/refresh`, refreshTokenBody)

                if (refreshTokenResponse.status === 201) {
                    const refreshTokenData = refreshTokenResponse.data

                    setUser(refreshTokenData.user)
                    setToken(refreshTokenData.token)

                    browserAPIRequest.defaults.headers.common['authorization'] =
                        `Bearer ${refreshTokenData.token}`

                    destroyCookie(undefined, token_name)
                    destroyCookie(undefined, refresh_token_name)

                    setCookie(undefined, token_name, refreshTokenData.token, {
                        maxAge: 8 * 60 * 60, // 40 minutes
                        path: '/',
                        sameSite: 'none',
                        secure: true,
                    })

                    setCookie(undefined, refresh_token_name, refreshTokenData.refresh_token, {
                        maxAge: 72 * 60 * 60, // 72 hours
                        path: '/',
                        sameSite: 'none',
                        secure: true,
                    })
                }

                return
            } catch (error) {
                Alert({
                    type: 'error',
                    text: `Erro: ${error}`
                })

                if (router.asPath === '/') {
                    return
                }

                router.push('/')
            } finally {
                setIsLoading(false)
            }

            getNewTokenInterval = setTimeout((): Promise<void> => getNewToken(), 2100000)
        }

        getNewToken()

        return (): void => {
            // clearInterval(getNewTokenInterval)
        }
    }, [])

    async function login(email: string, password: string, callback?: () => void): Promise<void> {
        setIsLoading(true)
        const data: IUserAuth = {
            email,
            password
        }

        try {
            const response = await browserAPIRequest.post('/admins/login', data)

            if (response.status === 201) {
                const data = response.data
                setUser(data.user)
                setToken(data.token)

                browserAPIRequest.defaults.headers.common['authorization'] =
                    `Bearer ${data.token}`

                setCookie(undefined, token_name, data.token, {
                    maxAge: 60 * 60 * 8, // 8h
                    path: '/',
                    sameSite: 'none',
                    secure: true,
                })

                setCookie(undefined, refresh_token_name, data.refresh_token, {
                    maxAge: 72 * 60 * 60, // 72 hours
                    path: '/',
                    sameSite: 'none',
                    secure: true,
                })

                if (callback) {
                    callback()
                }
            }
        } catch (error: any) {
            Alert({
                type: 'error',
                text: 'Verifique suas credencias e tente novamente!'
            })
        }
        finally {
            setIsLoading(false)
        }
    }

    function logout(callback?: () => void): void {
        setUser(undefined)
        setToken('')
        destroyCookie(undefined, token_name)
        destroyCookie(undefined, refresh_token_name)

        if (callback) {
            callback()
        }
    }

    return (
        <AuthContext.Provider value={{ login, user, token, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)