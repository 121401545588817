import { FaCookie } from 'react-icons/fa'
import styles from './styles.module.css'

type Props = {
    handleAccept: () => void
}

export function CookieConsent({ handleAccept }: Props): JSX.Element {

    return <div className={styles.cookies}>
        <span>Cookies</span>
        <p>Usamos cookies para melhorar a sua experiência. Você aceita o uso de cookies?</p>
        <p>Acesse nossa <a href='/politica-de-privacidade'>política de privacidade</a></p>
        <div className={styles.button}>
            <button onClick={handleAccept}>Aceitar</button>
        </div>
    </div>
}
