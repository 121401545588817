import { parseCookies, setCookie } from 'nookies'
import { createContext, useContext, useEffect, useState } from 'react'
import ChildrenProvider from './children.provider'
import { CookieConsent } from '@/components/cookiesConsent'
import { Loading } from '@/components/loading'

interface ICookieConsentContextData {
}

export const CookieConsentContext = createContext({} as ICookieConsentContextData)

export const CookieConsentProvider = ({ children }: ChildrenProvider): JSX.Element => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookies = parseCookies()

        if (!cookies['accept_cookies']) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {

        setCookie(undefined, 'accept_cookies', 'true', {
            maxAge: 360 * 24 * 60 * 60, // 72 hours
            path: '/',
            sameSite: 'none',
            secure: true,
        })
        setIsVisible(false);
    };

    const handleDecline = () => {
        setCookie(undefined, 'accept_cookies', 'false', {
            expires: 365, path: '/', sameSite: 'none',
            secure: true,
        });
        setIsVisible(false);
    };

    return (
        <CookieConsentContext.Provider value={{}}>
            {isVisible && <CookieConsent handleAccept={handleAccept} />}
            {children}
        </CookieConsentContext.Provider>
    )
}

export const useCookieConsent = () => useContext(CookieConsentContext)