import { strings } from '@/strings';
import axios, { AxiosInstance } from 'axios';
import { parseCookies } from 'nookies';

export function clientAPIRequest(context?: any): AxiosInstance {
    const { token_escotilha } = parseCookies(context);
    const api = axios.create({
        baseURL: strings.backend_url,
    })

    if (token_escotilha) {
        api.defaults.headers['authorization'] = `Bearer ${token_escotilha}`
    }

    return api
}

export const browserAPIRequest = clientAPIRequest()