import Swal from 'sweetalert2'

interface Props {
    title?: string
    text?: string
    html?: string
    onConfirm?: () => void
    type: 'warning' | 'error' | 'success' | 'info' | 'question'
}

export function Alert({ type, title, text, html, onConfirm }: Props) {
    const iconColor = {
        'warning': '#FCB600',
        'error': '#e13125',
        'success': '#0AC21F',
        'info': '#1850f5',
        'question': '#1850f5',
    }
    const textDefault = {
        'warning': '',
        'error': 'Ocorreu um erro, entre em contato com o suporte',
        'success': 'Ação concluída com sucesso',
        'info': '',
        'question': '',
    }

    const titleDefault = {
        'warning': 'Atenção...',
        'error': 'Ops...',
        'success': 'Sucesso!',
        'info': 'Atenção...',
        'question': '',
    }

    Swal.fire({
        icon: type,
        title: title ?? titleDefault[type],
        text: text ?? textDefault[type],
        html,
        inputAttributes: {
            autocapitalize: "off"
        },
        confirmButtonText: 'Ok',
        confirmButtonColor: '#28A745',
        customClass: {
            confirmButton: 'confirm-button-class',
            cancelButton: 'cancel-button-class'
        },
        iconColor: iconColor[type],
    }).then(e=>{
        if(onConfirm)
            onConfirm()
    })
}