import { Loading } from "@/components/loading";
import { AuthProvider } from "@/hooks/auth";
import { CookieConsentProvider } from "@/hooks/cookieConsent";
import { LoadingProvider } from "@/hooks/loading";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import 'react-quill-new/dist/quill.snow.css';

export default function App({ Component, pageProps }: AppProps) {

  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return <LoadingProvider>
    <CookieConsentProvider>
      <AuthProvider>
        {loading && <Loading />}
        <Component {...pageProps} />
      </AuthProvider>
    </CookieConsentProvider>
  </LoadingProvider>
}
