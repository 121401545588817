import { Loading } from '@/components/loading'
import { createContext, useContext, useState } from 'react'
import ChildrenProvider from './children.provider'

interface ILoadingContextData {
    setIsLoading: (value: boolean) => void
}

export const LoadingContext = createContext({} as ILoadingContextData)

export const LoadingProvider = ({ children }: ChildrenProvider): JSX.Element => {

    const [isVisible, setIsVisible] = useState(false);


    function setIsLoading(value: boolean) {
        setIsVisible(value)
    }

    return (
        <LoadingContext.Provider value={{ setIsLoading }}>
            {isVisible && <Loading />}
            {children}
        </LoadingContext.Provider>
    )
}

export const useLoading = () => useContext(LoadingContext)